<template>
  <a @click.prevent="openPopup">
    <slot></slot>
  </a>
</template>

<script>
const options = 'menubar=0,toolbar=0,status=0,resizable=1,scrollbars=0,'

export default {
  props: {
    href: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 300
    }
  },
  methods: {
    openPopup() {
      window.open(this.href, '', options + `height=${this.height},width=${this.width}`)
    }
  }
}
</script>
