var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"site-header"},[_c('div',{staticClass:"site-header__inner"},[_c('div',{staticClass:"site-header__logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('AspirinLogo')],1)],1),_c('button',{staticClass:"site-header__burger",on:{"click":function($event){return _vm.toggleMenu()}}}),_c('nav',{staticClass:"navigation"},[(_vm.$route.name == 'home')?_c('ul',{staticClass:"navigation__list"},[_c('li',{class:[
            'navigation__item',
            { 'is-active': _vm.activeSection == 'film' }
          ]},[_c('anchor-link',{attrs:{"to":"film"}},[_vm._v("Film")])],1),_c('li',{class:[
            'navigation__item',
            { 'is-active': _vm.activeSection == 'das-besser-gefuehl' }
          ]},[_c('anchor-link',{attrs:{"to":"das-besser-gefuehl"}},[_vm._v("Das „Besser“ Gefühl")])],1),_c('li',{class:[
            'navigation__item',
            { 'is-active': _vm.activeSection == 'emotionserkennung' }
          ]},[_c('anchor-link',{attrs:{"to":"emotionserkennung"}},[_vm._v("Emotionserkennung")])],1),_c('li',{class:[
            'navigation__item',
            { 'is-active': _vm.activeSection == 'gewinnspiel' }
          ]},[_c('anchor-link',{attrs:{"to":"gewinnspiel"}},[_vm._v("Gewinnspiel")])],1),_c('li',{class:[
            'navigation__item',
            { 'is-active': _vm.activeSection == 'aspirin-produkte' }
          ]},[_c('anchor-link',{attrs:{"to":"aspirin-produkte"}},[_vm._v("Aspirin® Produkte")])],1)]):_c('ul',{staticClass:"navigation__list"},[_c('li',{staticClass:"navigation__item"},[_c('router-link',{attrs:{"to":{ path: '/' }}},[_vm._v("Home")])],1)]),_c('div',{staticClass:"navigation__meta-wrapper"},[_c('meta-navigation',{staticClass:"navigation__nav navigation__nav--meta"})],1),_vm._m(0)]),_c('a',{staticClass:"site-header__bayer",attrs:{"href":"https://www.bayer.de/","target":"_blank"}},[_c('BayerLogo')],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"navigation__disclaimer"},[_vm._v("\n        ASPIRIN"),_c('sub',[_vm._v("®")]),_vm._v(" ist ein eingetragenes Markenzeichen von Bayer.\n        Copyright © Bayer AG.\n      ")])}]

export { render, staticRenderFns }