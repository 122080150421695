<template>
  <div>
    <transition name="flash">
      <div class="flasher" v-if="isFiring"></div>
    </transition>
  </div>
</template>

<script>
import eventBus from '@/util/event-bus'

export default {
  mounted() {
    eventBus.$on('fire-flash', this.fire)
  },
  data() {
    return {
      isFiring: false
    }
  },
  methods: {
    fire() {
      this.isFiring = true
      this.$nextTick(() => {
        this.isFiring = false
      })
    }
  }
}
</script>

<style lang="stylus">
.flasher
  display block
  position fixed
  z-index 100
  top 0
  left 0
  right 0
  bottom 0
  background-color white

.flash
  &-enter-active
    transition opacity 5s ease
  &-leave-active
    transition opacity 1s ease

  &-enter-to
    opacity 1
  &-leave-to
    opacity 0
</style>
