<template>
  <header class="site-header">
    <div class="site-header__inner">
      <div class="site-header__logo">
        <router-link to="/"><AspirinLogo /></router-link>
      </div>
      <button class="site-header__burger" @click="toggleMenu()"></button>
      <nav class="navigation">
        <ul class="navigation__list" v-if="$route.name == 'home'">
          <li
            :class="[
              'navigation__item',
              { 'is-active': activeSection == 'film' }
            ]"
          >
            <anchor-link to="film">Film</anchor-link>
          </li>
          <li
            :class="[
              'navigation__item',
              { 'is-active': activeSection == 'das-besser-gefuehl' }
            ]"
          >
            <anchor-link to="das-besser-gefuehl">Das „Besser“ Gefühl</anchor-link>
          </li>
          <li
            :class="[
              'navigation__item',
              { 'is-active': activeSection == 'emotionserkennung' }
            ]"
          >
            <anchor-link to="emotionserkennung">Emotionserkennung</anchor-link>
          </li>
          <li
            :class="[
              'navigation__item',
              { 'is-active': activeSection == 'gewinnspiel' }
            ]"
          >
            <anchor-link to="gewinnspiel">Gewinnspiel</anchor-link>
          </li>
          <li
            :class="[
              'navigation__item',
              { 'is-active': activeSection == 'aspirin-produkte' }
            ]"
          >
            <anchor-link to="aspirin-produkte">Aspirin® Produkte</anchor-link>
          </li>
        </ul>
        <ul class="navigation__list" v-else>
          <li class="navigation__item">
            <router-link :to="{ path: '/' }">Home</router-link>
          </li>
        </ul>
        <div class="navigation__meta-wrapper">
          <meta-navigation class="navigation__nav navigation__nav--meta" />
        </div>
        <p class="navigation__disclaimer">
          ASPIRIN<sub>®</sub> ist ein eingetragenes Markenzeichen von Bayer.
          Copyright © Bayer AG.
        </p>
      </nav>
      <a class="site-header__bayer" href="https://www.bayer.de/" target="_blank"
        ><BayerLogo
      /></a>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import MetaNavigation from './MetaNavigation'
import AnchorLink from './AnchorLink'
import AspirinLogo from '@/assets/images/logo.svg?inline'
import BayerLogo from '@/assets/images/bayer_logo.svg?inline'

export default {
  components: {
    MetaNavigation,
    AnchorLink,
    AspirinLogo,
    BayerLogo
  },
  computed: {
    ...mapState(['activeSection', 'isMenuOpen'])
  },
  methods: {
    toggleMenu() {
      this.$store.commit('setIsMenuOpen', !this.isMenuOpen)
    }
  },
  watch: {
    isMenuOpen(isOpen) {
      const $body = document.querySelector('body')

      if (isOpen) {
        $body.style.overflow = 'hidden'
      } else {
        $body.style.overflow = ''
      }
    }
  }
}
</script>

<style lang="stylus">
@require '../assets/style/1-settings/colors'
@require '../assets/style/1-settings/fonts'
@require '../assets/style/1-settings/layout'
@require '../assets/style/2-tools/responsive'
@require '../assets/style/5-objects/panel'

.site-header
  width 100%
  height $headerBarHeightMobile
  position fixed
  top 0
  left 0
  right 0
  z-index 4
  background-color $colorSiteBackground
  background-color rgba(16, 16, 16, 0.9)

  responsive(
    (padding-left 20px) \
    (padding-right 20px),
    (padding-left 80px) \
    (padding-right 80px)
  )

  &__inner
    lost-center $siteInnerMaxWidth
    display flex
    align-items center
    height 100%

  &__logo svg
    display block
    width 116px
    height 26px
    position relative
    top -2px

  &__bayer
    display block
    width 37px
    height 37px
    margin-left auto

  &__burger
    background transparent
    width 20px
    height 20px
    margin-left auto
    border none
    display block
    position relative
    cursor pointer

    &:before,
    &:after
      content ''
      display block
      width 100%
      height 2px
      background-color white
      position absolute
      top 50%
      left 0
      transition transform .05s ease

    &:before
      transform translate(0, -3px)
      .menu-open &
        transform translate(0, 0) rotate(45deg)

    &:after
      transform translate(0, 2px)
      .menu-open &
        transform translate(0, 0) rotate(-45deg)

    &:focus,
    &:active
      outline none
      background transparent

  +above($menuBreakpoint)
    height $headerBarHeight
    &__logo
      lost-column 2/12
    &__burger
      display none

  +below($menuBreakpoint)
    &__logo
      width auto
    &__bayer
      display none

.navigation
  +below($menuBreakpoint)
    display flex
    flex-direction column
    justify-content space-evenly
    position absolute
    top $headerBarHeightMobile
    left 100%
    bottom 0
    right 0
    width 100%
    height "calc(100vh - %s)" % $headerBarHeightMobile
    height "calc(var(--vh, 1vh) * 100 - %s)" % $headerBarHeightMobile
    padding-top 2rem
    background-image url('../assets/images/menu_bg@2x.jpg')
    background-size cover
    background-repeat no-repeat
    transition left 0.3s ease

    responsive(
      (padding-left 20px) \
      (padding-right 20px),
      (padding-left 80px) \
      (padding-right 80px)
    )

    .menu-open &
      left 0

    &__item
      margin-bottom 1.7rem
      margin-left 0.5rem

      &:before
        display inline-block
        width 16px
        height 16px
        margin-right 2rem
      &:nth-child(1):before
        content url('../assets/images/icon-video.svg')
      &:nth-child(2):before
        content url('../assets/images/icon-content.svg')
      &:nth-child(3):before
        content url('../assets/images/icon-face-recognition.svg')
      &:nth-child(4):before
        content url('../assets/images/icon-gewinnspiel.svg')
      &:nth-child(5):before
        content url('../assets/images/icon-products.svg')
      a
        font-family $fontSemiBold
        font-size 22px

      &.is-active > a
        border-bottom 1px solid white
    &__nav
      display inline-block

    &__meta-wrapper
      width 100%
      padding-left 1rem
      display flex

      &:before
        content ''
        width 1px
        display block
        margin-right 0.5rem
        background-color $colorBrand

    &__disclaimer
      width 100%
      text-align center
      font-family $fontSemiBold
      font-size 12px

  +above($menuBreakpoint)
    &__meta-wrapper,
    &__disclaimer
      display none

    &__list
      display flex

    &__item
      a
        font-family $fontBold
        font-size 15px

      &.is-active > a
        border-bottom 1px solid currentColor

      &:not(:first-child)
        margin-left 2.5rem
</style>
