import './polyfills.js'

import Vue from 'vue'
import Root from './Root.vue'
import router from './router'
import store from './store'
import './assets/style/main.styl'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(Root)
}).$mount('#as-root')
