<template>
  <a :href="`#${to}`" @click.prevent="onClick">
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      $target: null,
      pageHasTarget: false
    }
  },
  methods: {
    scrollToTarget() {
      window.scrollTo({ top: this.$target.offsetTop, behavior: 'smooth' })
    },
    onClick() {
      if(this.to == 'emotionserkennung' && window.fbq) {
        window.fbq('trackCustom', 'FacetestContest')
      }
      this.scrollToTarget()
      this.$store.commit('setIsMenuOpen', false)
    }
  },
  mounted() {
    this.$target = document.querySelector(`a[name='${this.to}']`)
  }
}
</script>
