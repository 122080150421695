<template>
  <div
    id="as-root"
    :class="{ 'menu-open': isMenuOpen, loading: isSiteLoading }"
  >
    <site-header />
    <main class="site-content">
      <router-view />
    </main>
    <site-footer />
    <flasher />
  </div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader'
import SiteFooter from '@/components/SiteFooter'
import Flasher from '@/components/Flasher'
import MobileDetect from 'mobile-detect'
import vhCheck from 'vh-check'
import { mapState } from 'vuex'

export default {
  components: {
    SiteHeader,
    SiteFooter,
    Flasher
  },
  computed: {
    ...mapState(['isMenuOpen', 'isSiteLoading'])
  },
  mounted() {
    const device = new MobileDetect(window.navigator.userAgent)
    vhCheck({
      redefineVh: true,
      force: device.mobile() !== null
    })

    if (window.location.hash) {
      const hash = window.location.hash.substring(1)
      history.replaceState({}, document.title, '.')

      window.addEventListener('load', event => {
        const $target = document.querySelector(`a[name='${hash}']`)
        if ($target) {
          window.scrollTo({ top: $target.offsetTop })
        }
      })
    }
  },
  watch: {
    '$store.state.isSiteLoading'(isLoading) {
      if (!isLoading) {
        document.querySelector('body').style.overflowY = 'scroll'
        setTimeout(() => {
          const spinner = document.querySelector('.pre-spinner')
          spinner.parentNode.removeChild(spinner)
        }, 1000)
      }
    }
  }
}
</script>

<style lang="stylus">
#as-root
  transition opacity 0.3s ease
  &.loading
    opacity 0
</style>
