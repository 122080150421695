<template>
  <footer class="site-footer  o-panel">
    <div class="o-panel__inner">
      <div class="site-footer__meta-wrapper">
        <AspirinLogo class="logo" />
        <meta-navigation class="site-footer__meta-nav" />
        <BayerLogo />
      </div>
      <aside class="legal">
        <section class="legal__section" :class="{ 'is-open': isOpen[0] }">
          <div class="legal__head" @click="toggleVisibility(0)">
            <span>
              <PlusIcon class="plus" v-if="!isOpen[0]" />
              <MinusIcon class="minus" v-else />
            </span>
            <h2 class="legal__heading">Pflichttexte</h2>
          </div>
          <slide-up-down class="legal__body" :active="isOpen[0]">
            <div class="legal__inner">
              <h2 class="legal__heading legal__heading--small">Wirkstoffe</h2>
              <p>
                <strong
                >Aspirin® 500 mg überzogene Tablette, Aspirin® Direkt,
                  Aspirin® Effect, Aspirin® Migräne</strong
                ><br />
                Wirkstoff: Acetylsalicylsäure
              </p>
              <p>
                <strong
                >Aspirin® Plus C, Aspirin® Plus C Forte 800 mg / 480 mg
                  Brausetabletten</strong
                ><br />
                Wirkstoffe: Acetylsalicylsäure und Ascorbinsäure (Vitamin C)
              </p>
              <p>
                <strong>Aspirin® Coffein</strong><br />
                Wirkstoffe: Acetylsalicylsäure und Coffein
              </p>
              <p>
                <strong>Aspirin® Complex</strong><br />
                Wirkstoffe: Acetylsalicylsäure und Pseudoephedrinhydrochlorid
              </p>
            </div>
            <div class="legal__inner">
              <h2 class="legal__heading legal__heading--small">Anwendungsgebiete</h2>
              <p>
                <strong>Aspirin® 500 mg überzogene Tabletten:</strong> Zur symptomatischen Behandlung von Fieber und/oder leichten bis mäßig starke Schmerzen wie z.B. Kopfschmerzen, Schmerzen im Rahmen eines grippalen Infekts, Zahnschmerzen sowie Muskelschmerzen. Bitte beachten Sie die Angaben für Kinder und Jugendliche.
              </p>
              <p>
                <strong>Aspirin® Effect:</strong> Leichte bis mäßig starke Schmerzen, Fieber. Bitte beachten Sie die Angaben für Kinder.
              </p>
              <p>
                <strong
                >Aspirin® Direkt, Aspirin® Plus C, Aspirin® Plus C Forte:</strong
                >
                Leichte bis mäßig starke Schmerzen wie Kopfschmerzen,
                Zahnschmerzen, Regelschmerzen, schmerzhafte Beschwerden, die im
                Rahmen von Erkältungskrankheiten auftreten (z. B. Kopf-, Hals-
                und Gliederschmerzen); Fieber. Bitte beachten Sie die Angaben für Kinder.
              </p>
              <p>
                <strong>Aspirin® Migräne:</strong> Akute Behandlung der Kopfschmerzphase von Migräneanfällen mit und ohne Aura.
              </p>
              <p>
                <strong>Aspirin® Coffein:</strong> Leichte bis mäßig starke Schmerzen, z.B. Kopf-, Zahn-, Regelschmerzen, Entzündungen. Bitte beachten Sie die Angaben für Kinder.
              </p>
              <p>
                <strong>Aspirin® Complex:</strong> Zur symptomatischen Behandlung von Schleimhautschwellung der Nase und Nebenhöhlen bei Schnupfen (Rhinosinusitis) mit Schmerzen und Fieber im Rahmen einer Erkältung bzw. eines grippalen Infektes.
              </p>
            </div>
            <div class="legal__inner">
              <h2 class="legal__heading legal__heading--small">Hinweise</h2>
              <p>
                <strong>Aspirin® 500 mg überzogene Tabletten:</strong> Enthält Natriumcarbonat. Bei Schmerzen oder Fieber ohne ärztlichen Rat nicht länger anwenden als in der Packungsbeilage vorgegeben!
              </p>
              <p>
                <strong>Aspirin® Effect:</strong> Enthält Natriumcarbonat. Bei Schmerzen oder Fieber ohne ärztlichen Rat nicht länger anwenden als in der Packungsbeilage vorgegeben!
              </p>
              <p>
                <strong>Aspirin® Direkt, Aspirin® Plus C, Aspirin® Plus C Forte:</strong>  Enthält Natriumverbindungen. Bitte Packungsbeilage beachten!
              </p>
              <p>
                <strong>Aspirin® Direkt:</strong> Der Farbstoff Gelborange S (E110) kann Überempfindlichkeitsreaktionen hervorrufen. Enthält Aspartam. Bitte Packungsbeilage beachten!
              </p>
              <p>
                <strong>Aspirin® Migräne:</strong> Enthält Natriumverbindungen. Bitte Packungsbeilage beachten!
              </p>
              <p>
                <strong>Aspirin® Complex:</strong> Nehmen Sie dieses Arzneimittel ohne ärztlichen Rat nicht länger als 3 Tage ein. Enthält 2 g Sucrose (Zucker) pro Beutel. Bitte Packungsbeilage beachten!
              </p>
              <p>
                Zu Risiken und Nebenwirkungen lesen Sie die Packungsbeilage und
                fragen Sie Ihren Arzt oder Apotheker.<br />Bayer Vital GmbH,
                51368 Leverkusen, Deutschland
              </p>
            </div>
          </slide-up-down>
        </section>
      </aside>
      <p class="site-footer__copyright">
        ASPIRIN<sup>®</sup> ist ein eingetragenes Markenzeichen von Bayer.
        Copyright © Bayer AG.
      </p>

      <div class="site-footer__social">

        <ShareLink class="fb" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsymphonyofbetter.de" :width="626" :height="800">
          <IconFacebookSimple />
        </ShareLink>

        <ShareLink class="tw" href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fsymphonyofbetter.de" :width="600" :height="254">
          <IconTwitterSimple />
        </ShareLink>

        <a class="link" href="mailto:?&subject=Aspirin%20Symphony%20of%20Better&body=https%3A%2F%2Fsymphonyofbetter.de" target="_blank">
          <IconLinkSimple />
        </a>
      </div>

      <button class="back-to-top" @click="gotoTop()"><BackToTopBtn /></button>
    </div>
  </footer>
</template>

<script>
import Vue from 'vue'
import ShareLink from '@/components/ShareLink'
import MetaNavigation from './MetaNavigation'
import AspirinLogo from '@/assets/images/logo.svg?inline'
import BayerLogo from '@/assets/images/bayer_logo.svg?inline'

import PlusIcon from '@/assets/images/new/plus.svg?inline'
import MinusIcon from '@/assets/images/new/minus.svg?inline'
import BackToTopBtn from '@/assets/images/btn-back-to-top.svg?inline'

import IconInstagramSimple from '@/assets/images/icon-instagram-simple.svg?inline'
import IconFacebookSimple from '@/assets/images/icon-facebook-simple.svg?inline'
import IconLinkSimple from '@/assets/images/icon-link-simple.svg?inline'
import IconYouTubeSimple from '@/assets/images/icon-yt.svg?inline'
import IconTwitterSimple from '@/assets/images/icon-twitter.svg?inline'

const SlideUpDown = () => import('vue-slide-up-down')
Vue.component('slide-up-down', SlideUpDown)

export default {
  components: {
    MetaNavigation,
    AspirinLogo,
    BayerLogo,
    PlusIcon,
    MinusIcon,
    BackToTopBtn,
    IconFacebookSimple,
    IconLinkSimple,
    IconTwitterSimple,
    ShareLink
  },
  data() {
    return {
      isOpen: [false]
    }
  },
  methods: {
    toggleVisibility(index) {
      Vue.set(this.isOpen, index, !this.isOpen[index])
    },
    gotoTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="stylus">
@require '../assets/style/1-settings/layout'
@require '../assets/style/1-settings/colors'
@require '../assets/style/1-settings/fonts'
@require '../assets/style/2-tools/responsive'

.site-footer
  color white
  background-image linear-gradient(180deg, #225756, #33323D);

  &__meta-wrapper
    display flex
    align-items center
    margin-bottom 3rem
    padding-top 4rem

    +from-width('l')
      padding-top 6rem
      margin-bottom 5rem

    .logo
      margin-top -8px

    svg:first-child
      responsive(
        (width 130px) \
        (height 25px),
        (width 136px) \
        (height 31px)
      )
      +below(800px)
        display none

    svg:last-child
      responsive(
        (width 26px) \
        (height 26px),
        (width 36px) \
        (height 36px)
      )
      +below(800px)
        display none

  &__meta-nav
    width 100%

  &__legal
    margin-bottom 4rem

    p
      font-family 'Arial'
      font-size 14px
      line-height 1.8
      opacity 0.7
      &:last-child
        padding-top 2rem
      +mobile()
        font-size 13px

  &__copyright
    margin-bottom 1.5rem
    font-family $fontSemiBold
    text-align center
    font-size 11px
    opacity 0.3

  &__social
    display block
    margin 0 auto
    background $colorBrand
    width 11.5rem
    height 4rem
    padding 0 2rem
    display flex
    justify-content space-between
    align-items center

    a
      display block
      height 23px
      width 23px
      text-align center

    .fb
      > svg
        width 10px
        height 20px

.legal
  margin-bottom 5rem

  &__section
    +above('m')
      padding-left 4rem

  &__section
    border-top 1px solid #57636A
    border-bottom 1px solid #57636A

  &__head
    padding 1rem 0
    display flex
    cursor pointer
    opacity 0.85

    .plus,
    .minus
      width 20px
      height 20px

    > span
      display block
      width 1.25rem
      height 3rem
      line-height 2.9rem
      margin-right 1.5rem

  &__body
    +above('m')
      margin-left 2.9rem

  &__inner
    padding-bottom 2rem
    max-width 800px

  &__heading
    margin 0
    font-size 22px
    line-height 50px
    display flex
    &--small
      font-size 18px

.back-to-top
  position absolute
  top 0
  right 5.5rem
  transform translate(0, -50%)

  width 60px
  height 60px
  border-radius 30px

  border none
  background-image linear-gradient(180deg, #00D3C2 0%, #00A88F 100%)

  > svg
    margin-top -4px
    width 22px
    height 13px

  +mobile()
    top 3rem
    right 1rem
</style>
