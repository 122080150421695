import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeSection: '',
    photoData: null,
    photoPath: null,
    usePhotoFallback: false,
    analysis: null,
    sweepstakeSubmitted: false,
    isMenuOpen: false,
    isSiteLoading: true
  },
  mutations: {
    clearPhoto(state) {
      state.photoPath = null
      state.photoData = null
    },
    setPhotoData(state, data) {
      state.photoData = data
    },
    setPhotoPath(state, path) {
      state.photoPath = path
    },
    setUsePhotoFallback(state, value) {
      state.usePhotoFallback = value
    },
    setAnalysis(state, analysis) {
      state.analysis = analysis
    },
    setSweepstakeSubmitted(state, submitted) {
      state.sweepstakeSubmitted = submitted
    },
    setActiveSection(state, section) {
      state.activeSection = section
    },
    setIsMenuOpen(state, open) {
      state.isMenuOpen = open
    },
    setIsSiteLoading(state, isLoading) {
      state.isSiteLoading = isLoading
    }
  },
  actions: {}
})
