import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import store from '@/store'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    store.commit('setIsMenuOpen', false)

    if (to.hash) {
      const $el = document.querySelector(to.hash)
      Vue.nextTick(() => {
        return window.scrollTo({ top: $el.offsetTop, behavior: 'smooth' })
      })
    }
    return window.scrollTo({ top: 0, behavior: 'smooth' })
  }
})

router.afterEach(() => {
  store.commit('setIsMenuOpen', false)
})

export default router
