const Home = () => import('@/pages/Home')
const Meta = () => import('@/pages/Meta')

const routes = [
  { path: '/', component: Home, name: 'home' },
  { path: '/impressum', component: Meta, name: 'imprint' },
  { path: '/datenschutz', component: Meta, name: 'privacy' },
  { path: '/teilnahmebedingungen', component: Meta, name: 'terms' }
]

export default routes
